import React, { useEffect, useState } from "react"
import { getData, wenJuan } from "./api/api_const"

import Modal from "react-modal"
import axios from "axios"
import "./App.css"
import LiheImg from "./lihe.png"
interface Props {
  location: any
}

interface ImgItem {
  ID: number
  ImgUrl: string
  Name: string
}
export function getUrlParam(name: any, str: any) {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`)
  const r = str.substr(1).match(reg)
  if (r != null) return decodeURIComponent(r[2])
  return null
}
Modal.setAppElement("#root")
const customStyles = {
  content: {
    top: "45%",
    left: "45%",
    right: "40%",
    bottom: "35%",
    marginRight: "-50%",
    transform: "translate(-43%, -40%)",
    borderRadius: "5px",
    height: "130px",
  },
}
function isAndroidSystem() {
  var u = navigator.userAgent
  var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1 //android终端
  return isAndroid
}
//判断是否是iOS终端
function isiOSSystem() {
  var u = navigator.userAgent
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
  return isiOS
}
function App(props: Props) {
  const [images, setImages] = useState<ImgItem[]>([])
  const [selectApp, setSelectApp] = useState<number>(0)
  const [userID, setUserID] = useState<string | null>("")
  const [open, setOpen] = useState<boolean>(false)
  useEffect(() => {
    let userid: string | null = getUrlParam("userid", props.location.search)
    if (userid === null) {
      return
    }
    initPage(userid)
  }, [])
  const initPage = async (userid: string | null) => {
    setUserID(userid)
    const res = await axios.get(getData + userid)
    const { data, errcode, errmsg } = res.data
    if (errcode === 0) {
      setImages(data)
    }
  }
  const handleIconClick = (id: number) => {
    setSelectApp(() => {
      return id
    })
  }
  const handleSubmit = async () => {
    if (selectApp === -1) {
      const res = await axios.get(
        wenJuan + "&userid=" + userID + "&txt=以上都不是"
      )
      setOpen(true)
      return
    }
    let temp = images.find((image) => {
      return image.ID === selectApp
    })
    const res = await axios.get(
      wenJuan + "&userid=" + userID + "&txt=" + temp?.Name
    )
    setOpen(true)
  }
  const handleConfirm = () => {
    if (isAndroidSystem()) {
      try {
        ;(window as any).j2nObj.jump2Native("gdongbitway://coupon/list")
        return
      } catch (err) {
        console.log(err)
        return
      }
    }
    if (isiOSSystem()) {
      try {
        ;(window as any).webkit.messageHandlers.passValue.postMessage([
          "gdongbitway://coupon/list",
        ])
        return
      } catch (err) {
        console.log(err)
        return
      }
    }
  }
  // display: flex;
  // justify-content: center; /* 水平居中 */
  // align-items: center;     /* 垂直居中 */
  // width: 1000px;
  // height: 600px;
  // border: 1px solid red;
  if (open) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <img src={LiheImg} alt=''></img>
          <div style={{ height: "10px" }}></div>
          <div style={{ fontSize: "12px" }}>
            感谢参与！奖励已发送至我的礼券！
          </div>
        </div>
      </div>
    )
  }
  return (
    <div>
      <Modal
        isOpen={false}
        onAfterOpen={() => {
          console.log("onAfterOpen")
        }}
        onRequestClose={() => {
          console.log("onRequestClose")
        }}
        style={customStyles}
        contentLabel='Example Modal'
      >
        <div style={{ position: "relative" }}>
          <div
            style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}
          >
            提示
          </div>
          <div style={{ height: "20px" }}></div>
          <div style={{ fontSize: "12px", textAlignLast: "center" }}>
            感谢参与，礼券已发送。
          </div>
          <div style={{ height: "30px" }}></div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <button
              style={{
                backgroundColor: "#F8D148",
                // fontSize: "1.0rem",
                padding: "5px",
                paddingRight: "50px",
                paddingLeft: "50px",
                border: "none",
                borderRadius: "50px",
                outline: "none",
              }}
              disabled={selectApp === 0}
              onClick={handleConfirm}
            >
              确定
            </button>
          </div>
        </div>
      </Modal>
      <div style={{ height: "30px" }}></div>
      <div style={{ marginLeft: "20px", fontSize: 14 }}>
        您是从什么渠道了解并下载G动的
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          margin: "5%",
        }}
      >
        <div>
          {images.map((image: ImgItem, index: number) => {
            return (
              <div
                style={{
                  display: "inline-block",
                  margin: "3%",
                  width: "19%",
                  flexShrink: 1,
                }}
                key={image.ID}
                onClick={() => {
                  handleIconClick(image.ID)
                }}
              >
                <img style={{ width: "100%" }} src={image.ImgUrl} alt=''></img>
                <div
                  style={{
                    wordBreak: "keep-all",
                    position: "relative",
                    overflow: "hidden",
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <label style={{ alignItems: "center", display: "flex" }}>
                    <input
                      name={image.ImgUrl}
                      type='checkbox'
                      checked={image.ID === selectApp}
                      value={image.ID}
                      style={{ border: "1px solid #000", borderRadius: "50%" }}
                      className='tui-checkbox'
                    />
                    <span style={{ fontSize: "10px", marginLeft: "2px" }}>
                      {image.Name}
                    </span>
                  </label>
                </div>
              </div>
            )
          })}
        </div>
        <div style={{ height: "30px" }}></div>
        {userID === null || userID !== "" ? (
          <div
            style={{ marginLeft: "10px" }}
            onClick={() => {
              handleIconClick(-1)
            }}
          >
            <label style={{ alignItems: "center", display: "flex" }}>
              <input
                name={""}
                type='checkbox'
                checked={-1 === selectApp}
                value={-1}
                style={{ border: "1px solid #000", borderRadius: "50%" }}
                className='tui-checkbox'
              />
              <span style={{ fontSize: "10px", marginLeft: "2px" }}>
                以上都不是
              </span>
            </label>
          </div>
        ) : (
          <div>未获取到用户</div>
        )}
      </div>
      {userID === null || userID !== "" ? (
        <div style={{ width: "100%", textAlign: "center" }}>
          <button
            style={{
              backgroundColor: "#F8D148",
              // fontSize: "1.0rem",
              padding: "10px",
              paddingRight: "60px",
              paddingLeft: "60px",
              border: "none",
              borderRadius: "50px",
              outline: "none",
            }}
            disabled={selectApp === 0}
            onClick={handleSubmit}
          >
            提交
          </button>
        </div>
      ) : null}
    </div>
  )
}

export default App
