/*
 * @Author: your name
 * @Date: 2020-09-08 16:23:13
 * @LastEditTime: 2020-09-09 22:05:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /gdong_answer/src/api/api_const.ts
 */
const defaultUrl = "http://h5page.wo-ish.com"
// 获取页面资源接口：  传递参数  userid
// 男 ：  000036C5D0544ED29C528FEE85832B6A
// 女 ：  00006BDDF6C6422C9218CD2AFE6C571D
const getData = "/Handler/activities/wenjuan2020.ashx?type=getdata&userid="

// 传递参数   userid    用户id
//                 txt         名称
//   提交数据接口：http://h5page.wo-ish.com/Handler/activities/wenjuan2020.ashx?type=save&userid=0005B1D1C6454D6F8C6C0321BC8892CA&txt=%E5%B0%8F%E7%BA%A2%E4%B9%A6
const wenJuan = "/Handler/activities/wenjuan2020.ashx?type=save"
export { defaultUrl, getData, wenJuan }
